import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-easter-egg',
  templateUrl: './easter-egg.component.html',
  styleUrls: ['./easter-egg.component.scss']
})
export class EasterEggComponent implements OnInit {
  isDark: boolean = false;
  @Input ('top') top: number = 200;
  @Output() toggleEasterEggScene = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.isDark = !this.isDark;
    this.toggleEasterEggScene.emit(this.isDark);
  }
}
