<svg
  class="close-btn"
  (click)="modalRef.hide()"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM17.6719 15.0938C17.9062 15.2812 17.9062 15.6562 17.6719 15.8906L15.8438 17.7188C15.6094 17.9531 15.2344 17.9531 15.0469 17.7188L12 14.625L8.90625 17.7188C8.71875 17.9531 8.34375 17.9531 8.10938 17.7188L6.28125 15.8438C6.04688 15.6562 6.04688 15.2812 6.28125 15.0469L9.375 12L6.28125 8.95312C6.04688 8.76562 6.04688 8.39062 6.28125 8.15625L8.15625 6.32812C8.34375 6.09375 8.71875 6.09375 8.95312 6.32812L12 9.375L15.0469 6.32812C15.2344 6.09375 15.6094 6.09375 15.8438 6.32812L17.6719 8.15625C17.9062 8.39062 17.9062 8.76562 17.6719 8.95312L14.625 12L17.6719 15.0938Z"
  />
</svg>

<div id="video-container" [innerHtml]="video| safeHtml"></div>