<nav class="navbar navbar-expand-lg navbar-light">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li
        *ngFor="let link of links"
        class="nav-item"
        [ngClass]="link.links && link.links.length ? 'dropdown' : ''"
      >
        <a
          class="nav-link"
          [ngClass]="link.links && link.links.length ? 'dropdown-toggle' : ''"
          [href]="link.url"
          [innerHTML]="link.title"
          [attr.role]="link.links && link.links.length ? 'button' : null"
          [attr.data-toggle]="link.links && link.links.length ? 'dropdown' : null"
        >
        </a>
        <div *ngIf="link.links && link.links.length" class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a *ngFor="let sublink of link.links" class="dropdown-item" [href]="sublink.url" [innerHTML]="sublink.title"></a>
          </div>
      </li>
    </ul>
  </div>
</nav>
