import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { MapOptions, tileLayer, latLng, marker, Map, divIcon } from 'leaflet';
import 'leaflet/dist/images/marker-shadow.png';
import Marzipano from 'marzipano';
import 'leaflet-rotatedmarker';
import { LayersService } from '@shared/services/layers.service';
import { Subscription } from 'rxjs';
import { StateService } from '@shared/services/state.service';
import { Viewpoint } from '@models/viewpoint.model';

@Component({
  selector: 'app-minimap',
  templateUrl: './minimap.component.html',
  styleUrls: ['./minimap.component.scss'],
})
export class MinimapComponent implements OnInit {
  @Input('viewer') viewer: Marzipano.Viewer;
  @Input('viewpoint') viewpoint: Viewpoint;
  @Input('viewpoints') viewpoints: any[];
  @Input('zoom') zoom: number = 19;
  currentMarker: any;
  options: MapOptions;
  markers: any[] = [];
  yaw: number = 0;

  map: Map;
  state: any = {};
  updateViewpointSub: Subscription;
  updateStateSub: Subscription;
  private layersControl: any;
  private currentOverlays: any[];
  private allOverlays: any[];
  private OVERLAY_NAME = 'Proposed';

  constructor(
    private router: Router,
    private zone: NgZone,
    private viewpointsService: ViewpointsService,
    private layersService: LayersService,
    private stateService: StateService,
    private ngZone: NgZone
  ) {
    this.state = { ...this.state, ...this.stateService.getState() };
    this.updateViewpointSub = this.viewpointsService.onUpdateViewpoint.subscribe(
      (viewpoint) => {
        this.update(viewpoint);
      }
    );
    // this.updateStateSub = this.stateService.onUpdateState.subscribe((state) => {
    //   this.state = { ...this.state, ...state };
    //   this.toggleOverlay(this.OVERLAY_NAME);
    // });
  }

  ngOnInit(): void {
    if (!this.viewpoint) {
      throw new Error('No viewpoint provided');
    }
    if (!this.viewpoint.coords) {
      throw new Error('No coords provided');
    }
    let that = this;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(function () {
        that.allOverlays = that.layersService.getOverlays();
        that.currentOverlays = { ...that.allOverlays };
        /************* FIXME: This causes lag when devtools are open ******************/
        that.layersControl = {
          overlays: that.currentOverlays,
        };
        that.setModeOverlay();
        /************* END FIXME ******************/
      }, 500);
    });

    this.options = {
      layers: [
        tileLayer(
          // for a street map instead, use this:
          //'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: '',
            maxZoom: 19,
            minZoom: 15, // limiting he field of view in the map
          }
        ),
      ],
      zoomControl: false,
      zoom: this.zoom,
      center: latLng(this.viewpoint.coords.lat, this.viewpoint.coords.lng),
    };

    let markers = [];
    if (this.viewpoints) {
      for (let i = 0; i < this.viewpoints.length; i++) {
        const viewpoint = this.viewpoints[i];
        let newMarker = this.getMarker(viewpoint);
        markers.push(newMarker);
      }
    }
    this.markers = [...markers];
  }

  setModeOverlay() {
    // this.ngZone.runOutsideAngular(() => {
      // home screen always has proposed overlay
      // if (!this.viewpoint.parent || this.state.global.mode === 'proposed') {
         const layer = this.layersControl['overlays'][this.OVERLAY_NAME];
        if (layer && this.map) {
          this.map.addLayer(layer);
        }
      // }
    // });
  }

  ngOnDestroy(): void {
    this.state = {};
    this.allOverlays = [];
    this.currentOverlays = [];
    this.layersControl = {};
    this.updateViewpointSub.unsubscribe();
    // this.updateStateSub.unsubscribe();
  }

  update(viewpoint) {
    this.currentMarker.setRotationAngle(
      this.getHeading(viewpoint.yaw) + this.getHeading(viewpoint.yawOffset)
    );
  }

  getMarker(viewpoint) {
    // Using divIcon so can apply theme color (from css variable)
    const customIcon = divIcon({
      className: 'custom-marker',
      iconAnchor: [30.5, 30.5],
      html: this.getMarkerHTML(),
    });
    let newMarker = marker([viewpoint.coords.lat, viewpoint.coords.lng], {
      rotationAngle:
        this.getHeading(viewpoint.yaw) + this.getHeading(viewpoint.yawOffset),
      icon: customIcon,
      title: viewpoint.title,
    });
    newMarker.on('click', () => {
      this.zone.run(() => {
        this.router.navigateByUrl(`/viewpoint?name=${viewpoint.slug}`);
      });
    });
    if (viewpoint.slug === this.viewpoint.slug) {
      this.currentMarker = newMarker;
    }
    return newMarker;
  }

  getHeading(yaw) {
    return (yaw * 180.0) / Math.PI;
  }

  toggleOverlay = function (overlayName) {
    const layer = this.layersControl['overlays'][overlayName];
    if (!layer) {
      return;
    }
    if (this.map.hasLayer(layer)) {
      this.map.removeLayer(layer);
    } else {
      this.map.addLayer(layer);
    }
  };

  onMapReady(map: Map) {
    this.map = map;
  }

  getMarkerHTML() {
    return `<style>
    .custom-marker {
      color: var(--clr-secondary);
      position: absolute;
      // margin-left: -16px;
      // margin-top: -5px;
    }
    }</style>
    <svg ${this.viewpoint.slug}-marker" width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="30.5" cy="31.0001" rx="30.5" ry="30.0001" fill="black" fill-opacity="0.2"/>
    <path d="M45.5 4.51818C49.9201 7.06946 53.6137 10.7045 56.2342 15.0769L30.5 30.9047L4.76574 15.0769C7.38623 10.7045 11.0798 7.06947 15.4999 4.51819C20.0605 1.88583 25.2338 0.500001 30.5 0.5C35.7661 0.499999 40.9394 1.88583 45.5 4.51818Z" fill="currentColor" stroke="white"/>
    <path d="M37.5 30.9979C37.5 35.1387 34.1423 38.4958 30 38.4958C25.8577 38.4958 22.5 35.1387 22.5 30.9979C22.5 26.857 25.8577 23.5 30 23.5C34.1423 23.5 37.5 26.857 37.5 30.9979Z" fill="currentColor" stroke="white"/>
    </svg>
`;
  }
}
