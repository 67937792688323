<div class="zoom-indicator">
    <span class="decrement-btn" (click)="zoomOut()"
      ><svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M10 0.53125C5.18359 0.53125 1.28125 4.43359 1.28125 9.25C1.28125 14.0664 5.18359 17.9688 10 17.9688C14.8164 17.9688 18.7188 14.0664 18.7188 9.25C18.7188 4.43359 14.8164 0.53125 10 0.53125ZM5.35938 10.6562C5.11328 10.6562 4.9375 10.4805 4.9375 10.2344V8.26562C4.9375 8.05469 5.11328 7.84375 5.35938 7.84375H14.6406C14.8516 7.84375 15.0625 8.05469 15.0625 8.26562V10.2344C15.0625 10.4805 14.8516 10.6562 14.6406 10.6562H5.35938Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0.28125"
            y="0.53125"
            width="19.4375"
            height="19.4375"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </span>
    <span class="zoom-level-lbl"><p [innerHTML]="displayZoomLevel"></p></span>
    <span class="increment-btn"  (click)="zoomIn()"
      ><svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M10 0.53125C5.18359 0.53125 1.28125 4.43359 1.28125 9.25C1.28125 14.0664 5.18359 17.9688 10 17.9688C14.8164 17.9688 18.7188 14.0664 18.7188 9.25C18.7188 4.43359 14.8164 0.53125 10 0.53125ZM15.0625 10.2344C15.0625 10.4805 14.8516 10.6562 14.6406 10.6562H11.4062V13.8906C11.4062 14.1367 11.1953 14.3125 10.9844 14.3125H9.01562C8.76953 14.3125 8.59375 14.1367 8.59375 13.8906V10.6562H5.35938C5.11328 10.6562 4.9375 10.4805 4.9375 10.2344V8.26562C4.9375 8.05469 5.11328 7.84375 5.35938 7.84375H8.59375V4.60938C8.59375 4.39844 8.76953 4.1875 9.01562 4.1875H10.9844C11.1953 4.1875 11.4062 4.39844 11.4062 4.60938V7.84375H14.6406C14.8516 7.84375 15.0625 8.05469 15.0625 8.26562V10.2344Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0.28125"
            y="0.53125"
            width="19.4375"
            height="19.4375"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </span>
  </div>
  