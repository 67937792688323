import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-root',
  template: `
  <app-header *ngIf="app.type === 'standalone'"></app-header>
  <router-outlet></router-outlet>
  <app-ts-logo></app-ts-logo>
  <app-footer *ngIf="app.type === 'standalone'"></app-footer>
  `
})
export class AppComponent {
  app: any;

  constructor(private titleService: Title) {
    this.app = app;
    this.titleService.setTitle(`${app.projectName} | ${app.organisationName}`);
  }
 }
