import { EventEmitter, Injectable, Output } from '@angular/core';

/**
 * Service for managing application state. State is stored in localstorage.
 */
@Injectable({
  providedIn: 'root'
})
export class StateService {

  @Output() onUpdateState = new EventEmitter();
  
  private INITIAL_STATE = {
    global: {
      mode: 'existing'
    },
    viewpoints: {}
  }
  private KEY = 's';

  constructor() { 
    let state = localStorage.getItem(this.KEY);
    if(!state){
      localStorage.setItem(this.KEY, JSON.stringify(this.INITIAL_STATE));
    }
  }

  /**
   * Get state for a particular viewpoint.
   * @param viewpointSlug 
   * @param prop 
   * @returns 
   */
  getViewpointState(viewpointSlug: string, prop?: string){
    let state = localStorage.getItem(this.KEY);
    if(state){
      let parsedState = JSON.parse(state);
      return prop ? parsedState.viewpoints[viewpointSlug][prop] : parsedState.viewpoints[viewpointSlug];
    }
    return this.INITIAL_STATE;
  }

  /**
   * Set state for a particular viewpoint.
   * @param viewpointSlug 
   * @param prop 
   * @param args 
   * @returns 
   */
  setViewpointState(viewpointSlug: string, prop: string, args: any){
    let state = this.getState();
    state.viewpoints[viewpointSlug][prop] = args;
    localStorage.setItem(this.KEY, JSON.stringify(state));
    return state;
  }

  /**
   * Get application state.
   * @returns 
   */
  getState(){
    let state = localStorage.getItem(this.KEY);
    if(state){
      return JSON.parse(state);
    }
    return this.INITIAL_STATE;
  }

  /**
   * Set application state.
   * @param state 
   * @returns 
   */
  setState(state: any){
    localStorage.setItem(this.KEY, JSON.stringify(state));
    this.onUpdateState.emit(state);
    return state;
  }

  
}
