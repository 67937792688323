import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ts-logo',
  templateUrl: './ts-logo.component.html',
  styleUrls: ['./ts-logo.component.scss']
})
export class TsLogoComponent implements OnInit {
  @Input('type') type: string = 'light'; 
  constructor() { }

  ngOnInit(): void {
  }

}
