<div class="social-links">
  <div class="row">
    <div class="col-md-12 text-center">
      <ul class="list-inline social-links">
        <li *ngFor="let link of socialLinks">
          <a
            [href]="link.url" 
            class="btn-social btn-outline"
            data-toggle="tooltip"
            data-placement="top"
            title="link.title"
            target="_blank"
          >
          <!-- facebook -->
          <svg *ngIf="link.slug === 'facebook'"
          width="8"
          height="13"
          viewBox="0 0 9 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.78906 7.25L7.11719 5.09375H5.03125V3.6875C5.03125 3.07812 5.3125 2.51562 6.25 2.51562H7.21094V0.664062C7.21094 0.664062 6.34375 0.5 5.52344 0.5C3.8125 0.5 2.6875 1.55469 2.6875 3.42969V5.09375H0.765625V7.25H2.6875V12.5H5.03125V7.25H6.78906Z"
          />
        </svg>
            <!-- twitter -->
            <svg *ngIf="link.slug === 'twitter'"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7578 3.0625C11.2266 2.71094 11.6484 2.28906 11.9766 1.79688C11.5547 1.98438 11.0625 2.125 10.5703 2.17188C11.0859 1.86719 11.4609 1.39844 11.6484 0.8125C11.1797 1.09375 10.6406 1.30469 10.1016 1.42188C9.63281 0.929688 9 0.648438 8.29688 0.648438C6.9375 0.648438 5.83594 1.75 5.83594 3.10938C5.83594 3.29688 5.85938 3.48438 5.90625 3.67188C3.86719 3.55469 2.03906 2.57031 0.820312 1.09375C0.609375 1.44531 0.492188 1.86719 0.492188 2.33594C0.492188 3.17969 0.914062 3.92969 1.59375 4.375C1.19531 4.35156 0.796875 4.25781 0.46875 4.07031V4.09375C0.46875 5.28906 1.3125 6.27344 2.4375 6.50781C2.25 6.55469 2.01562 6.60156 1.80469 6.60156C1.64062 6.60156 1.5 6.57812 1.33594 6.55469C1.64062 7.53906 2.55469 8.24219 3.63281 8.26562C2.78906 8.92188 1.73438 9.32031 0.585938 9.32031C0.375 9.32031 0.1875 9.29688 0 9.27344C1.07812 9.97656 2.36719 10.375 3.77344 10.375C8.29688 10.375 10.7578 6.64844 10.7578 3.39062C10.7578 3.27344 10.7578 3.17969 10.7578 3.0625Z"
              />
            </svg>
            <!-- instagram -->
            <svg *ngIf="link.slug === 'instagram'"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 2.80469C4.5 2.80469 3.30469 4.02344 3.30469 5.5C3.30469 7 4.5 8.19531 6 8.19531C7.47656 8.19531 8.69531 7 8.69531 5.5C8.69531 4.02344 7.47656 2.80469 6 2.80469ZM6 7.25781C5.03906 7.25781 4.24219 6.48438 4.24219 5.5C4.24219 4.53906 5.01562 3.76562 6 3.76562C6.96094 3.76562 7.73438 4.53906 7.73438 5.5C7.73438 6.48438 6.96094 7.25781 6 7.25781ZM9.42188 2.71094C9.42188 2.35938 9.14062 2.07812 8.78906 2.07812C8.4375 2.07812 8.15625 2.35938 8.15625 2.71094C8.15625 3.0625 8.4375 3.34375 8.78906 3.34375C9.14062 3.34375 9.42188 3.0625 9.42188 2.71094ZM11.2031 3.34375C11.1562 2.5 10.9688 1.75 10.3594 1.14062C9.75 0.53125 9 0.34375 8.15625 0.296875C7.28906 0.25 4.6875 0.25 3.82031 0.296875C2.97656 0.34375 2.25 0.53125 1.61719 1.14062C1.00781 1.75 0.820312 2.5 0.773438 3.34375C0.726562 4.21094 0.726562 6.8125 0.773438 7.67969C0.820312 8.52344 1.00781 9.25 1.61719 9.88281C2.25 10.4922 2.97656 10.6797 3.82031 10.7266C4.6875 10.7734 7.28906 10.7734 8.15625 10.7266C9 10.6797 9.75 10.4922 10.3594 9.88281C10.9688 9.25 11.1562 8.52344 11.2031 7.67969C11.25 6.8125 11.25 4.21094 11.2031 3.34375ZM10.0781 8.59375C9.91406 9.0625 9.53906 9.41406 9.09375 9.60156C8.39062 9.88281 6.75 9.8125 6 9.8125C5.22656 9.8125 3.58594 9.88281 2.90625 9.60156C2.4375 9.41406 2.08594 9.0625 1.89844 8.59375C1.61719 7.91406 1.6875 6.27344 1.6875 5.5C1.6875 4.75 1.61719 3.10938 1.89844 2.40625C2.08594 1.96094 2.4375 1.60938 2.90625 1.42188C3.58594 1.14062 5.22656 1.21094 6 1.21094C6.75 1.21094 8.39062 1.14062 9.09375 1.42188C9.53906 1.58594 9.89062 1.96094 10.0781 2.40625C10.3594 3.10938 10.2891 4.75 10.2891 5.5C10.2891 6.27344 10.3594 7.91406 10.0781 8.59375Z"
              />
            </svg>
            
            <!-- youtube -->
            <svg *ngIf="link.slug === 'youtube'"
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1172 1.42969C12.9766 0.867188 12.5312 0.421875 11.9922 0.28125C10.9844 0 7 0 7 0C7 0 2.99219 0 1.98438 0.28125C1.44531 0.421875 1 0.867188 0.859375 1.42969C0.578125 2.41406 0.578125 4.52344 0.578125 4.52344C0.578125 4.52344 0.578125 6.60938 0.859375 7.61719C1 8.17969 1.44531 8.60156 1.98438 8.74219C2.99219 9 7 9 7 9C7 9 10.9844 9 11.9922 8.74219C12.5312 8.60156 12.9766 8.17969 13.1172 7.61719C13.3984 6.60938 13.3984 4.52344 13.3984 4.52344C13.3984 4.52344 13.3984 2.41406 13.1172 1.42969ZM5.6875 6.42188V2.625L9.01562 4.52344L5.6875 6.42188Z"
              />
            </svg>
            

            <!-- linkedin -->
            <svg *ngIf="link.slug === 'linkedin'"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.09375 11V3.99219H0.914062V11H3.09375ZM1.99219 3.05469C2.69531 3.05469 3.25781 2.46875 3.25781 1.76562C3.25781 1.08594 2.69531 0.523438 1.99219 0.523438C1.3125 0.523438 0.75 1.08594 0.75 1.76562C0.75 2.46875 1.3125 3.05469 1.99219 3.05469ZM11.2266 11H11.25V7.15625C11.25 5.28125 10.8281 3.82812 8.625 3.82812C7.57031 3.82812 6.86719 4.41406 6.5625 4.95312H6.53906V3.99219H4.45312V11H6.63281V7.53125C6.63281 6.61719 6.79688 5.75 7.92188 5.75C9.04688 5.75 9.07031 6.78125 9.07031 7.60156V11H11.2266Z"
              />
            </svg>
          </a>
        </li>
        <!-- <li>
          <a
            href="https://twitter.com/#"
            class="btn-social btn-outline"
            title="Twitter"
            ><i class="icon ion-social-twitter"></i
          ></a>
        </li>
        <li>
          <a
            href="https://ph.linkedin.com/in/#"
            class="btn-social btn-outline"
            title="LinkedIn"
            ><i class="icon ion-social-linkedin"></i
          ></a>
        </li> -->
      </ul>
    </div>
  </div>
</div>
