
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

  public data: any;
  public video: any;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    if (this.data.slug == 'overview') {
      this.video = '<div class="wistia_embed wistia_async_7g3ljr0c56 autoPlay=true controlsVisibleOnLoad=false" style="width:100%;height:645px;">&nbsp;</div>'
    }
    else {
      this.video = '<video width="100%" height="100%" style="margin-bottom:-7px" controls autoplay><source src="assets/videos/' + this.data.slug + '.mp4" type="video/mp4" />Your browser does not support the video tag.</video>'
    }
  }
}
