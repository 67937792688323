import { Component, Input, OnInit } from '@angular/core';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import Marzipano from 'marzipano';
import { Subscription } from 'rxjs';
import { Viewpoint } from '@models/viewpoint.model';

@Component({
  selector: 'app-compass',
  templateUrl: './compass.component.html',
  styleUrls: ['./compass.component.scss'],
})
export class CompassComponent implements OnInit {
  @Input('viewer') viewer: Marzipano.Viewer;
  @Input('viewpoint') viewpoint: Viewpoint;
  yaw: number = 0;

  updateViewpointSub: Subscription;

  constructor(private viewpointsService: ViewpointsService) {
    this.updateViewpointSub = this.viewpointsService.onUpdateViewpoint.subscribe(
      (viewpoint) => {
        this.update(viewpoint);
      }
    );
  }

  ngOnInit(): void {
    if (this.viewer === undefined) {
      throw new Error('No Marzipano Viewer is provided');
    }
  }

  ngOnDestroy(): void {
    this.updateViewpointSub.unsubscribe();
  }

  update(viewpoint) {
    if (viewpoint.yaw) {
      if (!viewpoint.yawOffset) {
        viewpoint.yawOffset = 0;
      }
      let newYaw = this.getHeading(viewpoint.yaw)  + this.getHeading(viewpoint.yawOffset);
      this.yaw = newYaw;
    }
  }

  getHeading(yaw){
    return (yaw * 180.0) / Math.PI;
  }
}
