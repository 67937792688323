<svg
  (click)="toggle()"
  class="easter-egg"
  width="24"
  height="27"
  viewBox="0 0 24 27"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_d)">
    <path
      d="M6.375 10.125H6.83203C7.71094 12.1289 9.67969 13.5 12 13.5C14.2852 13.5 16.2539 12.1289 17.1328 10.125H17.625C17.9062 10.125 18.1875 9.87891 18.1875 9.5625V6.1875C18.1875 5.90625 17.9062 5.625 17.625 5.625H17.1328C16.2539 3.65625 14.2852 2.25 12 2.25C9.67969 2.25 7.71094 3.65625 6.83203 5.625H6.375C6.05859 5.625 5.8125 5.90625 5.8125 6.1875V9.5625C5.8125 9.87891 6.05859 10.125 6.375 10.125ZM7.78125 7.03125C7.78125 6.25781 8.51953 5.625 9.46875 5.625H14.5312C15.4453 5.625 16.2188 6.25781 16.2188 7.03125V7.875C16.2188 9.73828 14.707 11.25 12.8438 11.25H11.1562C9.29297 11.25 7.78125 9.73828 7.78125 7.875V7.03125ZM10.3125 9.5625L10.7344 8.29688L12 7.875L10.7344 7.45312L10.3125 6.1875L9.89062 7.45312L8.625 7.875L9.89062 8.29688L10.3125 9.5625ZM15.6211 13.5703C14.5664 14.2383 13.3359 14.625 12 14.625C10.6289 14.625 9.39844 14.2383 8.34375 13.5703C5.95312 13.8164 4.125 15.7852 4.125 18.2461V18.5625C4.125 19.5117 4.86328 20.25 5.8125 20.25H8.625V18C8.625 17.4023 9.11719 16.875 9.75 16.875H14.25C14.8477 16.875 15.375 17.4023 15.375 18V20.25H18.1875C19.1016 20.25 19.875 19.5117 19.875 18.5625V18.2461C19.875 15.7852 18.0117 13.8164 15.6211 13.5703ZM13.6875 18C13.3711 18 13.125 18.2812 13.125 18.5625C13.125 18.8789 13.3711 19.125 13.6875 19.125C13.9688 19.125 14.25 18.8789 14.25 18.5625C14.25 18.2812 13.9688 18 13.6875 18ZM10.3125 18C9.99609 18 9.75 18.2812 9.75 18.5625V20.25H10.875V18.5625C10.875 18.2812 10.5938 18 10.3125 18Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d"
      x="0.125"
      y="0.25"
      width="23.75"
      height="26"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="2" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow"
        result="shape"
      />
    </filter>
  </defs>
</svg>
