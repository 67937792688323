<div class="ab-test" [ngClass]="'vert-' + vert + ' ' + 'horiz-' + horiz"

[ngStyle]="{
    'top': (vert === 'top' ? offset[0] : null),
    'bottom': (vert === 'bottom' ? offset[0] : null),
    'left': (horiz === 'left' ? offset[1] : null),
    'right': (horiz === 'right' ? offset[1] : null)
}"
>
  <svg
    width="17"
    height="9"
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.18164 6.67188H2.83301L2.42285 8H0.591797L3.19922 0.890625H4.81055L7.4375 8H5.59668L5.18164 6.67188ZM3.24316 5.34863H4.77148L4.00488 2.88281L3.24316 5.34863ZM8.44824 8.61035H7.28125L9.70801 0.890625H10.8799L8.44824 8.61035ZM11.4756 8V0.890625H14.0293C14.944 0.890625 15.639 1.05827 16.1143 1.39355C16.5928 1.72884 16.832 2.21549 16.832 2.85352C16.832 3.22135 16.7474 3.53548 16.5781 3.7959C16.4089 4.05632 16.1598 4.24837 15.8311 4.37207C16.2021 4.46973 16.487 4.65202 16.6855 4.91895C16.8841 5.18587 16.9834 5.51139 16.9834 5.89551C16.9834 6.59212 16.762 7.11621 16.3193 7.46777C15.8799 7.81608 15.2272 7.99349 14.3613 8H11.4756ZM13.1895 4.97754V6.68164H14.3125C14.6217 6.68164 14.8594 6.61165 15.0254 6.47168C15.1914 6.32845 15.2744 6.12826 15.2744 5.87109C15.2744 5.27865 14.9798 4.98079 14.3906 4.97754H13.1895ZM13.1895 3.85449H14.083C14.4574 3.85124 14.7243 3.78288 14.8838 3.64941C15.0433 3.51595 15.123 3.31901 15.123 3.05859C15.123 2.75911 15.0368 2.54427 14.8643 2.41406C14.6917 2.2806 14.4134 2.21387 14.0293 2.21387H13.1895V3.85449Z"
    />
  </svg>
</div>
