import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ab-test',
  templateUrl: './ab-test.component.html',
  styleUrls: ['./ab-test.component.scss'],
})
export class AbTestComponent implements OnInit {
  @Input() position: string = 'top left';
  @Input() offset: number[] = [0,0];
  vert: string = 'vert-top';
  horiz: string = 'horiz-right';
  constructor() {
    
  }

  ngOnInit(): void {
    let position = this.position.split(' ');
    this.vert = `${position[0]}`;
    this.horiz = `${position[1]}`;
  }
}
