<div [id]="sceneId + '_' + hotspot?.slug" style="opacity: 0">
  <div
    *ngIf="hotspot?.args && hotspot?.args.label"
    class="label"
    [ngClass]="isShowInfo ? 'open' : ''"
    [ngStyle]="
      hotspot?.args.width && isShowInfo && { width: hotspot?.args.width + 'px' }
    "
  >
    <h3 [innerHTML]="hotspot.title"></h3>
    <div
      class="info-content"
      [ngClass]="isShowInfoContent ? 'open' : ''"
      [ngStyle]="hotspot?.args.width && { width: hotspot?.args.width + 'px' }"
    >
      <svg
        (click)="toggleInfo()"
        class="close-btn"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.80469 5.75L9.53906 3.01562C9.89453 2.6875 9.89453 2.14062 9.53906 1.8125L8.9375 1.21094C8.60938 0.855469 8.0625 0.855469 7.73438 1.21094L5 3.94531L2.23828 1.21094C1.91016 0.855469 1.36328 0.855469 1.03516 1.21094L0.433594 1.8125C0.078125 2.14062 0.078125 2.6875 0.433594 3.01562L3.16797 5.75L0.433594 8.51172C0.078125 8.83984 0.078125 9.38672 0.433594 9.71484L1.03516 10.3164C1.36328 10.6719 1.91016 10.6719 2.23828 10.3164L5 7.58203L7.73438 10.3164C8.0625 10.6719 8.60938 10.6719 8.9375 10.3164L9.53906 9.71484C9.89453 9.38672 9.89453 8.83984 9.53906 8.51172L6.80469 5.75Z"
        />
      </svg>

      <div [innerHTML]="hotspot?.content | safeHtml"></div>
      <div *ngIf="hotspot?.slideshow" style="width: 500px">
        <carousel
          cellWidth="100%"
          [height]="400"
          [images]="hotspot?.slideshow.images"
        >
        </carousel>
      </div>
    </div>
  </div>
  <div class="hotspot-wrapper">
    <h2
      *ngIf="hotspot?.args && hotspot?.args.featured"
      [innerHTML]="hotspot?.title"
    ></h2>
    <div
      *ngIf="hotspot?.type === 'video'"
      (click)="openDialog()"
      class="hotspot"
      [ngClass]="hotspot?.args.featured ? 'featured' : ''"
      [attr.data-slug]="hotspot?.slug"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.20676 5.30313L1.80288 0.753121C1.1929 0.381246 0.288452 0.753121 0.288452 1.65V10.75C0.288452 11.5813 1.1298 12.0844 1.80288 11.6687L9.20676 7.11875C9.85876 6.70313 9.85876 5.71875 9.20676 5.30313Z"
        />
      </svg>
    </div>
    <div
      *ngIf="hotspot?.type === 'info'"
      (click)="toggleInfo()"
      class="hotspot"
      [attr.data-slug]="hotspot?.slug"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.21875 10.4609C0.9375 10.4609 0.75 10.6719 0.75 10.9297V12.0312C0.75 12.3125 0.9375 12.5 1.21875 12.5H4.78125C5.03906 12.5 5.25 12.3125 5.25 12.0312V10.9297C5.25 10.6719 5.03906 10.4609 4.78125 10.4609H4.3125V5.46875C4.3125 5.21094 4.10156 5 3.84375 5H1.21875C0.9375 5 0.75 5.21094 0.75 5.46875V6.59375C0.75 6.85156 0.9375 7.0625 1.21875 7.0625H1.6875V10.4609H1.21875ZM3 0.5C2.0625 0.5 1.3125 1.27344 1.3125 2.1875C1.3125 3.125 2.0625 3.875 3 3.875C3.91406 3.875 4.6875 3.125 4.6875 2.1875C4.6875 1.27344 3.91406 0.5 3 0.5Z"
        />
      </svg>
    </div>
  </div>
</div>
